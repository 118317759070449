import React from 'react'
import Navbar from '../components/NavBar/NavBar'
import GlobalFooter from '../components/Footer/GlobalFooter'
import SundayWorship from '../components/SundayWorship/SundayWorship'
import SundayWorshipForm from '../components/SundayWorship/SundayWorshipForm'

export default () => {
    return (
      <div>
        <div>
          <Navbar />
        </div>
        <div>
          <SundayWorship />
        </div>
        <div>
          <SundayWorshipForm />
        </div>
        <div>
          <GlobalFooter />
        </div>
      </div>
      )
    }