import React from 'react'
import { Col, Row } from 'antd'
import './SundayWorshipForm.css'
// import Calendar from '../Calendar/calendar'
import ReactPlayer from 'react-player/youtube'

export default () =>{
    return(
        <div className='sundayWorshipFormContainer'>
            <Row>
                <Col span={24}>
                    <div style={{textAlign: 'center', marginBottom: '80px'}}>
                        <h2>主日安排</h2>
                        <p>
                            <strong>音樂敬拜</strong>: 10:00 am - 10:30 am
                        </p>
                        <p>
                            <strong>信息</strong>: 10:30 am - 11:20 am
                        </p>
                        <p>
                            <strong>主日學</strong>: 11:30 am  - 12:15 pm (因COVID-19暫時取消）
                        </p>
                        <p>
                            <strong>午餐</strong>: 12:15 pm  - 1:00 pm（因COVID-19暫時取消）
                        </p>
                        <p>
                            <strong>英文堂</strong>: 10:00 am  - 11:20 am
                        </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={16} offset={4} style={{textAlign: 'center'}}>
                    <h2>主日回顧</h2>
                    <div style={{position: 'relative', paddingTop: '56.25%'}}>
                        <ReactPlayer 
                            url="https://www.youtube.com/playlist?list=PLghqVEpa6I5MVyYc5ZXLC4o9dqPu1SJvb"
                            controls={true}
                            width='100%'
                            height='100%'
                            style={{position: 'absolute', top: 0, left: 0}}
                        ></ReactPlayer>
                    </div>
                </Col>
            </Row>
        </div>
    )
}