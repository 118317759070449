import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import './SundayWorship.css'


export default() =>{
    const data = useStaticQuery(graphql`
    query SundayWorshipQuery{
      file(relativePath: { eq: "Sundayworship_images/sundayworship.jpg" }) {
        childImageSharp {
          fluid (maxWidth:2000,quality:100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
    `)

    return (
        <div className= 'sundayWoshipHeaderContainer'> 
            <BackgroundImage fluid={data.file.childImageSharp.fluid} className='sundayWorshipHeaderBackground' alt="Jesus is smiling at you">
                <div className='sundayWorshipHeaderTitle'>
                    <h1>主 日 崇 拜</h1> 
                </div>
            </BackgroundImage>
        </div>
        
    )
}




